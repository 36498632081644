import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {faqUrl} from "../../constants/api/endpoints";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

export const useQueryFAQ = () => {
  const getFAQ = () => {
    return axios.get(backendBaseUrl + faqUrl, {
      headers: {
        "Authorization": "Bearer " + jsonToJwt({})
      }
    }).then(data => data.data.faq_questions)
  }

  return useQuery({
    queryKey: ['faq'],
    queryFn: getFAQ,
  })
}